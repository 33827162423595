import { createContext, useState } from "react";
import greenhouseApiUrl from "../utils/ApiUrl";

export const MainApiContext = createContext(null);

export default function MainApiProvider({ children }) {
  const [apiUrl, setApiUrl] = useState(
    `${greenhouseApiUrl}/departments`
  );

  return (
    <MainApiContext.Provider
      value={{
        api: apiUrl,
        setApi: setApiUrl,
      }}
    >
      {children}
    </MainApiContext.Provider>
  );
}
